import { graphql } from "gatsby"
import React from "react"

import CareerBenefitsList from "../../../components/career/career-benefits-list/career-benefits-list"
import CareerContact from "../../../components/career/career-contact/career-contact"
import CareerFaq from "../../../components/career/career-faq/career-faq"
import StudiesAndTrainingComparison from "../../../components/career/studies-and-training-comparison/studies-and-training-comparison"
import StudiesAndTrainingDownloadsGrid from "../../../components/career/studies-and-training-downloads-grid/studies-and-training-downloads-grid"
import TextWithOrWithoutGallery from "../../../components/dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../../../components/gallery/model/gallery-item"
import OverviewGrid from "../../../components/overview-grid/overview-grid"
import SEO from "../../../components/seo"
import Tile from "../../../components/tile/tile"
import TileContentSpacer from "../../../components/tile/tile-content-spacer/tile-content-spacer"
import SideNavView from "../../../components/views/side-nav-view/side-nav-view"
import useCareerContacts from "../../../hooks/useCareerContacts"
import useNav from "../../../hooks/useNav"
import { useTranslation } from "../../../i18n/translate"
import { CareerContactModel } from "../../../model/careerContactModel"
import { dynamicImageWithGatsbyImage } from "../../../util/dynamic-image"

const StudiesPage = ({ location, pageContext, data }) => {
  const { t } = useTranslation()

  const contacts = CareerContactModel.getCareerContactsByNames(
    useCareerContacts(),
    [
      ["Janina", "Amon"],
      ["Kathrin", "Zimmermann"],
    ],
  )
  const openVacanciesNav = useNav({ uid: "open-vacancies" })

  const faqItems = [
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_1-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_1-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_2-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_2-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_3-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_3-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_4-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_4-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_5-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_5-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_6-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_6-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_7-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_7-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_8-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_8-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_9-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_9-ANSWER"),
    },
    {
      translatedQuestion: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_10-QUESTION"),
      translatedAnswer: t("CAREER-STUDIES-STUDENT_FAQ_ITEM_10-ANSWER"),
    },
  ]

  const benefits = [
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_1",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_2",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_3",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_4",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_5",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_6",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_7",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_8",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_9",
    "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING-BENEFIT_10",
  ].map((b) => t(b))

  const studies = [
    {
      image: dynamicImageWithGatsbyImage(data.studyBusinessAdministration),
      description: t("CAREER-STUDIES-STUDY_1-DESCRIPTION"),
      title1: t("CAREER-STUDIES-STUDY_1-TITLE_1"),
      title2: t("CAREER-STUDIES-STUDY_1-TITLE_2"),
      title3: t("CAREER-STUDIES-STUDY_1-TITLE_3"),
      title4: t("CAREER-STUDIES-STUDY_1-TITLE_4"),
      thatAwaitsYou: t("CAREER-STUDIES-STUDY_1-THAT_AWAITS_YOU"),
      yourSkills: t("CAREER-STUDIES-STUDY_1-YOUR_SKILLS"),
      university: t("CAREER-STUDIES-STUDY_1-UNIVERSITY"),
      duration: t("CAREER-STUDIES-STUDY_1-DURATION"),
      diploma: t("CAREER-STUDIES-STUDY_1-DIPLOMA"),
      start: t("CAREER-STUDIES-STUDY_1-START"),
    },
    {
      image: dynamicImageWithGatsbyImage(data.studyChemicalEngineering),
      description: t("CAREER-STUDIES-STUDY_2-DESCRIPTION"),
      title1: t("CAREER-STUDIES-STUDY_2-TITLE_1"),
      title2: t("CAREER-STUDIES-STUDY_2-TITLE_2"),
      title3: t("CAREER-STUDIES-STUDY_2-TITLE_3"),
      title4: t("CAREER-STUDIES-STUDY_2-TITLE_4"),
      thatAwaitsYou: t("CAREER-STUDIES-STUDY_2-THAT_AWAITS_YOU"),
      yourSkills: t("CAREER-STUDIES-STUDY_2-YOUR_SKILLS"),
      university: t("CAREER-STUDIES-STUDY_2-UNIVERSITY"),
      duration: t("CAREER-STUDIES-STUDY_2-DURATION"),
      diploma: t("CAREER-STUDIES-STUDY_2-DIPLOMA"),
      start: t("CAREER-STUDIES-STUDY_2-START"),
    },
  ]

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("CAREER-STUDIES-META_TITLE")}
        descriptionTranslatedString={t("CAREER-STUDIES-META_DESCRIPTION")}
      />
      <SideNavView
        titleTranslatedString={t("MENU-CAREER-STUDIES_PAGE_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <Tile
              linkTextTranslatedString={t(
                t("CAREER-OPEN_VACANCIES-VIEW_OPEN_VACANCIES"),
              )}
              linkPath={
                openVacanciesNav.url + "?employmentTypes=educationstudy"
              }
            >
              <h2 className={"_fp-heading-1"}>{t("CAREER-STUDIES-TITLE")}</h2>
              <TileContentSpacer size={"extra-small"} />
              <TextWithOrWithoutGallery
                gallerySize={"large"}
                centerText={true}
                gallery={[
                  new GalleryPictureItem({
                    gatsbyFluidImage: dynamicImageWithGatsbyImage(
                      data.studiesImage,
                    ),
                  }),
                ]}
                textTranslationKey={"CAREER-STUDIES-TEXT"}
              ></TextWithOrWithoutGallery>
              <TileContentSpacer />
            </Tile>
          </div>
          <div className="_fp-col-12">
            <StudiesAndTrainingComparison
              items={studies}
              tileTitleTranslatedString={t(
                "CAREER-STUDIES-STUDIES_OVERVIEW_TITLE",
              )}
            >
              <TileContentSpacer size={"small"} />
              <p className={"_fp-text"}>
                {t("CAREER-STUDIES-APPLICATION_START_NOTICE")}
              </p>
              <TileContentSpacer size={"small"} />
              <StudiesAndTrainingDownloadsGrid
                downloads={[
                  data.studyFlyer,
                  data.aagvUrkunde,
                  data.agvAuszeichnung,
                ]}
              ></StudiesAndTrainingDownloadsGrid>
            </StudiesAndTrainingComparison>
          </div>
          <div className="_fp-col-12">
            <Tile
              titleTranslatedString={t(
                "CAREER-STUDIES-REASONS_TO_STUDY_AT_MUNZING",
              )}
            >
              <CareerBenefitsList benefits={benefits} />
            </Tile>
          </div>
          <div className="_fp-col-12">
            <Tile titleTranslatedString={t("CAREER-STUDIES-STUDENT_FAQ_TITLE")}>
              <CareerFaq faqItems={faqItems} />
            </Tile>
          </div>
          <div className="_fp-col-12">
            <Tile titleTranslatedString={t("CAREER-CAREER_CONTACT")} noTile>
              <OverviewGrid size={"small"}>
                {contacts.map((contact, index) => {
                  return (
                    <CareerContact
                      key={`recruiting-process-career-contact_${index}`}
                      contact={contact}
                    />
                  )
                })}
              </OverviewGrid>
            </Tile>
          </div>
        </div>
      </SideNavView>
    </>
  )
}

export default StudiesPage

export const query = graphql`
  {
    studiesImage: strapiDynamicImage(uid: { eq: "STUDIES" }) {
      ...DynamicImageWithGatsbyImage
    }
    studyChemicalEngineering: strapiDynamicImage(
      uid: { eq: "STUDY_CHEMICAL_ENGINEERING" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    studyBusinessAdministration: strapiDynamicImage(
      uid: { eq: "STUDY_BUSINESS_ADMINISTRATION" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    studyFlyer: strapiDynamicPdfFile(strapiId: { eq: 2 }) {
      ...DynamicPdfFileParts
    }
    aagvUrkunde: strapiDynamicPdfFile(strapiId: { eq: 3 }) {
      ...DynamicPdfFileParts
    }
    agvAuszeichnung: strapiDynamicPdfFile(strapiId: { eq: 4 }) {
      ...DynamicPdfFileParts
    }
  }
`
